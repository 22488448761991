<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner" :style="{ 'background-image': `url(${require('../../../../assets/images/common/top_banner_' + theme_color + '.png')})` }">
        <h1>{{ title }}报告</h1>
        <p>
          <span v-if="subtitle">{{ subtitle }}</span>
        </p>
      </div>
      <div class="btn_tab" id="emotion-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a data-href="#mtgd" class="selected" @click="switchTab(0, true)">媒体观点分析</a>
              <a data-href="#zfgd" @click="switchTab(1, true)">政府观点分析</a>
              <a data-href="#wbgd" @click="switchTab(2, true)">微博观点分析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 媒体观点分析 -->
        <a name="mtgd" id="mtgd"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">媒体观点分析
              <el-tooltip class="item" effect="dark" content="综合分析国内国外媒体观点后，得出的结论" placement="bottom">
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                在{{ data.summary.start_time }}至{{ data.summary.end_time }}期间的{{ data.summary.total_num }}条信息中，
                共{{ data.summary.domestic_num }}条信息来源于媒体，
                媒体报导中{{ data.summary.domestic_manner }}观点占主导，
                以下将罗列各来源的重要观点。</p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">媒体类型类别
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，媒体类型类别</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="state_sum"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">媒体观点情绪分布
                  <el-tooltip class="item" effect="dark" content="时间段内，国内媒体发布信息的情感分布" placement="bottom">
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="domestic_dis"></div>
                </div>
              </el-card>
            </el-col>

          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">媒体观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，国内媒体报导次数最多的<br>10个观点，及其报导数、数量占比和<br>代表媒体</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['domestic_media_analyse'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['domestic_media_analyse'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['domestic_media_analyse']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :key="index" :title="media"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmt_sxbh">
            <h3 class="mod-title">媒体观点时序变化
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">根据事件走势，展示关键时间点的国内<br>传播媒体和传播内容</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20" v-if="!data['opinion_domestic_media_chronological'].isIncubation">
              <!-- 时间轴 -->
              <div class="echarts_empty" v-show="data['opinion_domestic_media_chronological'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="demo" v-show="data['opinion_domestic_media_chronological'].data.length >= 1">
                <div class="main-timeline">
                  <div class="timeline" v-for="(row, index) in data['opinion_domestic_media_chronological'].data" :key="index" v-if="index < 20">
                    <div class="timeline-content">
                      <div class="circle">
                        <h5 class="title"><a :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a></h5>
                        <p class="xgbds"><span>相关报导数 ： {{ row.count }}条</span><span>信息占比 ： {{ row.percent }}%</span></p>
                        <p class="source">
                          <a v-for="(media, index) in row.media" :key="index">
                            <var>{{ media }}</var> <span>|</span>
                          </a>
                        </p>
                      </div>
                      <div class="cont">
                        <span class="year">{{ row.date }}</span>
                        <div class="icon"><span></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-lr-20" v-else>
              <div class="w-100 h-400" id="opinion_domestic_media_chronological"></div>
              <div class="demo2">
                <div class="main-timeline2" v-for="(row, index) in data['opinion_domestic_media_chronological'].data.timeline" :key="index">
                  <div class="item" v-if="row.type === 'point'">
                    <div class="main-title">
                      <div class="point">
                        <p v-for="(point, index) in row.points" :key="index">
                          <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                          <span>{{ point }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="circle_title">
                            <h4>
                              <span class="mg" v-if="row.article.emotion < 50">敏感</span>
                              <a :href="row.article.source_url" target="_blank">{{ row.article.title }}</a>
                            </h4>
                            <p><span>相关报导数：{{ row.article.total }}条</span><span>信息占比：{{ row.article.percent }}%</span></p>
                            <dl>
                              <dd v-for="(media, index) in row.article.medias" :key="index">
                                <a>{{ media }}</a><span class="bd">|</span>
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div class="cont">
                          <span class="year">{{ row.date }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item item_title" v-else>
                    <div class="main-title">
                      <h3>{{ row.state }}</h3>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content bd_n">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(article, index) in row.articles" :key="index">
                                <span class="top1" v-if="index === 0">1</span>
                                <span class="top2" v-if="index === 1">2</span>
                                <span class="top3" v-if="index === 2">3</span>
                                <a class="title">{{ article.title }}</a>
                                <a class="site"><span>|</span>{{ article.media }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">媒体重大负面观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，国内媒体报导次数最多的<br>10个负面观点，及其报导数、数量占<br>比和代表媒体</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_domestic_media_neg'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['opinion_domestic_media_neg'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_domestic_media_neg']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :key="index" :title="media"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">主要媒体观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，报导相关信息最多的6个<br>国内媒体及其最重要的3个观点</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_domestic_main_media'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_domestic_main_media'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_domestic_main_media']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index">
                          <a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_domestic_main_media'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_domestic_main_media']" :key="index" v-if="index <= 5 && index > 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index">
                          <a :href="article.source_url" target="_blank">{{ article.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 政府观点分析 -->
        <a name="zfgd" id="zfgd"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">政府观点分析
              <el-tooltip class="item" effect="dark" content="综合分析政府观点后，得出的结论" placement="bottom">
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                在{{ data.summary.start_time }}至{{ data.summary.end_time }}期间的{{ data.summary.total_num }}条信息中，
                {{ data.opinion_gov_analysis.gov_count }}条来自政府网站，
                代表了政府对关键词"{{ data.opinion_gov_analysis.word }}"的观点。其中{{ data.opinion_gov_analysis.gov_manner }}观点。
                以下将罗列来自政府网站的重要观点。
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">政府观点情绪分布
                  <el-tooltip class="item" effect="dark" content="时间段内，政府媒体发布信息的情感分布" placement="bottom">
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_gov_dis"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">主要政府观点来源分布
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，报导相关信息最多的6个<br>政府媒体的分布</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data.opinion_gov_main.type.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_gov_main" v-show="data.opinion_gov_main.type.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">政府网站观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，政府媒体报导次数最多的<br>10个观点，及其报导数、数量占比和<br>代表媒体</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_gov_manner'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['opinion_gov_manner'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_gov_manner']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :title="media" :key="index"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">主要政府网站观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，报导相关信息最多的6个<br>政府媒体及其最重要的3个观点</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_gov_main_media'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_gov_main_media'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_gov_main_media']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_gov_main_media'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_gov_main_media']" :key="index" v-if="index > 2 && index <= 5">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 微博观点分析 -->
        <a name="wbgd" id="wbgd"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博观点分析
              <el-tooltip class="item" effect="dark" content="综合分析微博观点后，得出的结论" placement="bottom">
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                在{{ data.summary.start_time }}至{{ data.summary.end_time }}期间的{{ data.summary.total_num }}条信息中，
                {{ data.opinion_weibo_analysis.total }}条来微博、其中包含{{ data.opinion_weibo_analysis.original }}条原发微博。
                {{ data.opinion_weibo_analysis.forward }}条转发微博。观点主要来自于{{ data.opinion_weibo_analysis.usertype }}，
                包含{{ data.opinion_weibo_analysis.usertype_count }}条微博。以下将罗列各用户类别的重要观点。
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">微博类型观点分布
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，微博原发或转发的信息<br>占微博发布的总信息数量的比重</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_weibo_forward"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">微博用户观点分布
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">时间段内，各类型微博用户发布的信息<br>占微博发布的总信息数量的比重</div>
                    <i class="iconfont icon-tishi"></i>
                  </el-tooltip>
                </h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data.opinion_weibo_usertype_list.type.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_weibo_usertype_list" v-show="data.opinion_weibo_usertype_list.type.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博原文观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，微博发布次数最多的10个<br>观点及其相关微博数、微博占比和代<br>表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_source_manner'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_source_manner'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_source_manner']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博转发观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，微博转发次数最多的10个<br>观点及其相关微博数、微博占比和代<br>表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_forward_manner'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_forward_manner'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_forward_manner']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">金V观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，金V发布次数最多的10个<br>观点及其相关微博数、微博占比和代<br>表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v1'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v1'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v1']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">橙V观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，橙V发布次数最多的10个<br>观点及其相关微博数、微博占比和代<br>表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v2'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v2'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v2']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">蓝V观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，蓝V发布次数最多的10个<br>观点及其相关微博数、微博占比和代<br>表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v3'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v3'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v3']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">达人观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，达人发布次数最多的10个<br>观点及其相关微博数、微博占比和代<br>表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v4'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v4'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v4']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博女郎
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，微博女郎发布次数最多的10个<br>观点及其相关微博数、微博占比和代表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v5'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v5'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v5']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">普通用户
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，普通用户发布次数最多的10个<br>观点及其相关微博数、微博占比和代表用户</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v6'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v6'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v6']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" v-if="user.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">意见领袖观点
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，微博发布相关信息数量最多<br>的6个用户及其最重要的3个观点</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_leader'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_weibo_leader'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_weibo_leader']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <div class="flex">
                        <a :href="row.home" target="_blank" class="img">
                          <img :src="row.avatar" width="60" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="60" v-else />
                          <span class="ico v_red" v-if="row.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        </a>
                        <div class="mar-t-10">
                          <h5><a :href="row.home" target="_blank">{{ row.name }}</a></h5>
                          <p>相关微博数：<span>{{ row.count }}条</span></p>
                        </div>
                      </div>
                      <ul>
                        <li v-for="(article, index) in row.article"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_weibo_leader'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_weibo_leader']" :key="index" v-if="index > 2 && index <= 5">
                    <div class="mtgd_je">
                      <div class="flex">
                        <a :href="row.home" target="_blank" class="img">
                          <img :src="row.avatar" width="60" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="60" v-else />
                          <span class="ico v_red" v-if="row.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        </a>
                        <div class="mar-t-10">
                          <h5><a :href="row.home" target="_blank">{{ row.name }}</a></h5>
                          <p>相关微博数：<span>{{ row.count }}条</span></p>
                        </div>
                      </div>
                      <ul>
                        <li v-for="(article, index) in row.article"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>

      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
import { findPointImg, globalGetEcharts, globalCreateChart } from '@/utils/helpers.js'
import { detail } from '@/api/analysis'
export default {
  name: 'analysis-view',
  data() {
    return {
      title: '',
      data: {},
      loading: true,
      created: '',
      expand_data: {}
    }
  },
  mounted() {
    this.loadData()
    this.$nextTick(() => {
      let _this = this
      window.onscroll = function() {
        let height = document.documentElement.scrollTop + 75
        if (height > 200) {
          $('#emotion-tab').addClass('scroll-tab')
          $('#emotion-tab').addClass('scroll')
        } else {
          $('#emotion-tab').removeClass('scroll')
          $('#emotion-tab').removeClass('scroll-tab')
        }
        let b = window.document.getElementById('zfgd').offsetTop
        let c = window.document.getElementById('wbgd').offsetTop
        if (height < b) {
          _this.switchTab(0)
        } else if (height >= b && height < c) {
          _this.switchTab(1)
        } else {
          _this.switchTab(2)
        }
      }
    })
  },
  methods: {
    switchTab(index, scrollTo = false) {
      if (scrollTo) {
        var h = 0
        switch (index) {
          case 0:
            document.documentElement.scrollTop = h
            break
          case 1:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('zfgd').offsetTop - h
            break
          case 2:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('wbgd').offsetTop - h
            break
        }
      }
      $('.flex-container a').removeClass('selected')
      $('.flex-container a:eq(' + index + ')').addClass('selected')
    },
    loadData() {
      detail({ id: this.$route.query.id })
        .then(res => {
          if (res.data.state) {
            this.data = JSON.parse(res.data.data.result)
            this.expand_data = JSON.parse(res.data.data.expand_data)
            const { title, updated, subtitle, theme_color } = res.data.data
            this.title = title
            this.subtitle = subtitle
            this.theme_color = theme_color
            this.created = updated
            this.loading = false
            this.$nextTick(() => {
              this.createChart()
            })
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(res => {
          this.$message.error('加载数据失败，服务失败')
        })
    },
    createChart() {
      let loopKeys = [
        'state_sum',
        'domestic_dis',
        'opinion_gov_dis',
        'opinion_gov_main',
        'opinion_weibo_forward',
        'opinion_weibo_usertype_list'
      ]
      for (let i = 0; i < loopKeys.length; i++) {
        let key = loopKeys[i]
        let data = this.data[key]
        this.loopChart(data, key)
      }
      if (this.data['opinion_domestic_media_chronological'].isIncubation) {
        this.eventTrend('opinion_domestic_media_chronological')
      }
    },
    eventTrend(id) {
      let data = this.data['opinion_domestic_media_chronological'].data.trend
      let keys = []
      let seriesData = []
      data.map(item => {
        keys.push(item.date)
        if (item.points) {
          seriesData.push({
            value: item.count,
            symbol: 'image://' + findPointImg(item.points[0]),
            name: item.points.join(',')
          })
        } else {
          seriesData.push({
            value: item.count,
            name: ''
          })
        }
      })
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: keys
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'line',
            stack: '总量',
            symbol: 'none',
            symbolSize: 20,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function(params) {
                  return params.data.name
                }
              }
            },
            data: seriesData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    loopChart(data, id) {
      // ["#83d944","#3178d2","#568efc","#8abaf8","#f0a142","#e7b42a","#ecc355","#ecc355"]
      var colors = ['#FC5D73', '#555DFE']
      var name = null
      var legend = []
      var chartData = []
      var rateMap = []
      switch (id) {
        case 'state_sum':
          name = '信息来源类别'
          colors = ["#83d944","#3178d2","#568efc","#8abaf8","#f0a142","#e7b42a","#ecc355","#ecc355"]
          const mediaData = this.expand_data.media_map
          legend = mediaData.map((item) => {
            return item.name
          })
          var total = 0
          chartData = mediaData.map((item) => {
            total += item.num
            return {
              name: item.name,
              value: item.num
            }
          })
          rateMap = mediaData.map((item) => {
            return ((item.num / total) * 100).toFixed(2)
          })
          break
        case 'domestic_dis':
          name = '国内媒体观点情绪分布'
          legend = ['负面', '正面']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.negative / total) * 100).toFixed(2),
                  ((data.positive / total) * 100).toFixed(2)
                ]
          break
        case 'oversea_dis':
          name = '国外媒体观点情绪分布'
          legend = ['负面', '正面']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.negative / total) * 100).toFixed(2),
                  ((data.positive / total) * 100).toFixed(2)
                ]
          break
        case 'opinion_gov_dis':
          name = '政府观点情绪分布'
          legend = ['负面', '正面']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.negative / total) * 100).toFixed(2),
                  ((data.positive / total) * 100).toFixed(2)
                ]
          break
        case 'opinion_gov_main':
          name = '主要政府观点来源分布'
          colors = [
            '#3479CF',
            '#F1A64F',
            '#82BBF5',
            '#4A9D67',
            '#55D9FF',
            '#67A5FF',
            '#74CCB1',
            '#EDCE05',
            '#EE8131',
            '#D94928'
          ]
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            chartData.push({ name: legend[i], value: data.data[i] })
          }
          for (let i = 0; i < data.data.length; i++) {
            if (total == 0) {
              rateMap.push(0)
            } else {
              rateMap.push(((data.data[i] / total) * 100).toFixed(2))
            }
          }
          break
        case 'opinion_weibo_forward':
          name = '微博类型观点分布'
          colors = ['#F2A64F', '#26A2F9']
          legend = ['转发', '原文']
          chartData = [
            { name: '转发', value: data.forward },
            { name: '原文', value: data.original }
          ]
          var total = parseInt(data.forward) + parseInt(data.original)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.forward / total) * 100).toFixed(2),
                  ((data.original / total) * 100).toFixed(2)
                ]
          break
        case 'opinion_weibo_usertype_list':
          name = '微博用户观点分布'
          colors = []
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            chartData.push({ name: legend[i], value: data.data[i] })
          }
          for (let i = 0; i < data.data.length; i++) {
            if (total == 0) {
              rateMap.push(0)
            } else {
              rateMap.push(((data.data[i] / total) * 100).toFixed(2))
            }
          }
          legend = legend.map((item, index) => {
            let temp = {
              name: item,
              textStyle: {
                color: '#ffa237'
              },
              itemGap: 20,
              itemWidth: 10,
              itemHeight: 10,
              icon: 'image:///static/img/v_red.png'
            }
            switch (item) {
              case '金V':
                colors.push('#c91d10')
                temp.textStyle.color = '#c91d10'
                temp.icon = 'image:///static/img/v_red.png'
                break
              case '黄V':
                colors.push('#fea700')
                temp.textStyle.color = '#fea700'
                temp.icon = 'image:///static/img/v_orange.png'
                break
              case '蓝V':
                colors.push('#09aaf7')
                temp.textStyle.color = '#09aaf7'
                temp.icon = 'image:///static/img/v_blue.png'
                break
              case '微博女郎':
                colors.push('#fc5d9a')
                temp.textStyle.color = '#fc5d9a'
                temp.icon = 'image:///static/img/v_pink.png'
                break
              case '达人':
                colors.push('#e8220e')
                temp.textStyle.color = '#e8220e'
                temp.icon = 'image:///static/img/v_talent_show.png'
                break
              default:
                colors.push('#0078d5')
                temp.textStyle.color = '#0078d5'
                temp.icon = 'circle'
                break
            }
            return temp
          })
          break
      }
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}({d}%)'
        },
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          orient: 'vertical',
          x: 'left',
          bottom: '0',
          data: legend,
          icon: 'circle',
          itemGap: 5,
          formatter: function(name) {
            let legendMap = []
            _(legend).forEach((item, index) => {
              if (typeof item == 'string') {
                legendMap.push(item)
              } else if (typeof item == 'object') {
                legendMap.push(item.name)
              }
            })
            let index = legendMap.indexOf(name)
            return name + ' ' + rateMap[index] + '%'
          }
        },
        color: colors,
        series: [
          {
            name: name,
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: chartData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    findPointImg(name) {
      return findPointImg(name)
    }
  }
}
</script>
<style scoped>
.fxbg.pt_0 {
  padding-top: 0;
}
/*flex-container */
.btn_tab {
  margin: auto;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 73px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}
.btn_tab a.selected {
  color: #fff;
  background: rgba(42, 103, 179, 1);
}
.scroll-tab {
  position: fixed;
  z-index: 3000;
  left: auto;
  right: auto;
  top: 200px;
  margin: auto;
}
.scroll {
  top: 0px;
}
.scroll .fxbg {
  box-shadow: 0px 3px 13px 0px rgba(194, 194, 194, 0.2);
  padding: 10px 0;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 95px;
  border: none;
}
.echarts_empty {
  height: 250px;
}
/*国内媒体观点*/
.gnmtgd .mod-list-title1 {
  width: 350px;
}
.gnmtgd .source {
  width: 475px;
  float: left;
}
.gnmtgd .source a {
  width: 174px;
  float: left;
}
.gnmtgd .source var {
  font-style: normal;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  float: left;
}
.gnmtgd .source span {
  padding: 0 20px;
  display: inline-block;
  color: rgba(180, 180, 180, 0.6);
  float: right;
}
.gnmtgd .source a:last-child {
  width: 127px;
}
.gnmtgd .source a:last-child span {
  display: none;
}
/*时间轴*/
.demo {
  width: 1116px;
  margin: 20px auto;
  padding: 45px 0 0;
}
.main-timeline {
  position: relative;
}
.main-timeline:before,
.main-timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline:before {
  content: '';
  width: 3px;
  height: calc(100% - 50px);
  background: #bebebe;
  position: absolute;
  top: 0;
  left: 50%;
}
.main-timeline .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 7px;
}
.main-timeline .circle {
  width: 469px;
  height: 90px;
  background: rgb(239, 244, 254);
  border-radius: 8px;
  float: left;
  position: relative;
  margin-top: -35px;
}
.main-timeline .cont {
  display: table;
  padding: 0 5px;
  position: relative;
}
.main-timeline .year {
  display: block;
  margin: 0 0 50px 0;
  border-radius: 7px;
  font-size: 12px;
  color: #fff;
  width: 79px;
  height: 19px;
  background-color: #2a67b3;
  border-radius: 4.5px;
  margin-top: -15px;overflow: hidden;
}
.main-timeline .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 0;
  right: -12px;
  display: inline-block;
}
.main-timeline .timeline:first-child .timeline-content:before {
  top: 7px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle {
  float: right;
}
.main-timeline .timeline:nth-child(even) {
  margin-top: 60px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before {
  top: 3px;
}
.main-timeline .timeline:nth-child(even) .year {
  margin-top: -20px;
}
.main-timeline .timeline:nth-child(even) .icon {
  left: -10px;
  right: 0;
}
.main-timeline h5,
.main-timeline .circle p {
  line-height: 24px;
  text-align: left;
  font-size: 12px;
  padding: 0 10px;
  height: 24px;
  overflow: hidden;
  color: rgb(71, 71, 71);
}
.main-timeline h5 {
  margin-bottom: 0;
  margin-top: 10px;
}
.main-timeline .circle .xgbds span {
  padding-right: 15px;
  color: rgb(71, 71, 71);
}
.main-timeline .circle .source var {
  font-style: normal;
  color: rgb(71, 71, 71);
}
.main-timeline .circle .source span {
  padding: 0 8px;
  color: rgb(71, 71, 71);
}
.main-timeline .circle .source a:last-child span {
  display: none;
}
/*txt_list*/
.txt_list .mtgd_je {
  background: rgba(230, 230, 230, 0.4);
  padding: 10px;
  border-radius: 6px;
}
.mtgd_je ul {
  height: 90px;
}
.txt_list h5,
.txt_list li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  line-height: 30px;
}
.txt_list h5 a {
  color: rgb(63, 144, 247);
}
.txt_list p {
  line-height: 30px;
  font-size: 12px;
}
.txt_list p span {
  color: #7d7d7d;
}
.txt_list li {
  border-bottom: 1px solid rgba(134, 134, 134, 0.2);
  font-size: 12px;
}
.txt_list li:last-child {
  border: none;
}
/*微博原文观点*/
.picture_list li {
  line-height: 40px;
  margin-bottom: 10px;
  height: auto;
}
.picture_list .mod-list-title2 {
  width: 370px;
  float: left;
  padding-right: 10px;
}
.picture_list a.mod-list-title2 {
  line-height: 20px;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  margin-top: 7px;
}
.picture_list .xgwbs {
  width: 100px;
  float: left;
  font-size: 12px;
  color: #000;
  text-align: center;
}
.picture_list .dbyh {
  width: 480px;
  text-align: left;
  float: left;
  color: #000;
}
.picture_list dl {
  width: 480px;
  float: left;
}
.picture_list dd {
  width: 160px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  float: left;
}
.picture_list dd .img,
.txt_list .img {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
}
.txt_list .img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.picture_list .img img,
.txt_list .img img {
  border-radius: 50%;
}
.picture_list .img .ico,
.txt_list .img .ico {
  width: 16px;
  height: 16px;
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.picture_list dd .name {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}
.txt_list .echarts_empty .img {
  width: 94px;
  height: 120px;
  margin: 0;
}
/*事件关键时间点*/
.demo2 {
  width: 1120px;
  margin: 30px auto 0;
  padding: 0;
  position: relative;
}
.demo2:before {
  content: '';
  width: 3px;
  background: #bebebe;
  position: absolute;
  top: 40px;
  left: 10%;
  bottom: 46px;
  height: calc(100% - 86px);
}
.main-timeline2 {
  position: relative;
}
.main-timeline2 .timeline {
  width: 90%;
  float: right;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
}
.main-timeline2 .timeline:before,
.main-timeline2 .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-title {
  width: 10%;
  float: left;
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.main-title .point {
  height: 70px;
  overflow: hidden;
}
.main-title p {
  line-height: 24px;
  overflow: hidden;
}
.main-title p span {
  font-size: 14px;
}
.main-title p .img {
  margin-right: 8px;
  vertical-align: middle;
}
.main-title h3 {
  width: 36px;
  height: 123px;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(199, 199, 199, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 18px;
  font-size: 20px;
  color: rgba(0, 103, 177, 1);
  line-height: 30px;
  text-align: center;
  padding-top: 13px;
  margin-left: 25px;
}
.main-timeline2 .timeline:last-child,
.main-timeline2 .main-title:nth-last-child(2) {
  padding-bottom: 0;
}
.main-timeline2 .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline2 .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 45px;
}
.main-timeline2 .circle {
  width: 830px;
  float: right;
  position: relative;
  text-align: left;
  overflow: hidden;
}
.main-timeline2 .circle_title,
.main-timeline2 .mod-list {
  background: rgb(239, 244, 254);
  border-radius: 8px;
}
.main-timeline2 .circle_title {
  line-height: 25px;
  padding: 8px 15px;
}
.main-timeline2 .circle_title h4,
.main-timeline2 .circle_title p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}
.main-timeline2 .circle_title p span {
  padding-right: 10px;
}
.main-timeline2 .circle_title p .bd {
  padding-left: 10px;
}
.main-timeline2 .circle_title dl {
  overflow: hidden;
  margin-bottom: 0;
}
.main-timeline2 .circle_title dd,
.main-timeline2 .circle_title a,
.main-timeline2 .circle_title .bd {
  float: left;
  font-size: 12px;
  color: #474747;
}
.main-timeline2 .circle_title .bd {
  padding: 0 15px;
}
.main-timeline2 .circle_title dd:last-child > .bd {
  display: none;
}
.main-timeline2 .circle_title h4 a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 740px;
}
.main-timeline2 .circle .mod-list {
  padding: 10px 15px;
  height: auto;
}
.main-timeline2 .circle .mod-list li {
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}
.main-timeline2 .mod-list li a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.main-timeline2 .mod-list li .mod-list-title2 {
  width: 680px;
}
.main-timeline2 .mod-list li .site {
  width: 80px;
  padding-left: 10px;
  color: #474747;
  float: left;
}
.main-timeline2 .site span {
  padding-right: 10px;
}
.main-timeline2 .cont {
  display: table;
  padding: 0 5px;
  position: relative;
  width: auto;
  margin: 0;
}
.main-timeline2 .year {
  display: block;
  margin: 0 0 0 27px;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  width: 112px;
  height: 38px;
  background-color: #2a67b3;
  border-radius: 8px;
  margin-top: 2px;
  line-height: 38px;
}
.main-timeline2 .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 38px;
  left: -10px;
  display: inline-block;
}
.main-timeline2 .item {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.demo2 .item:nth-child(2n) .timeline-content:before {
  display: none;
}
.demo2 .item:nth-child(2n) .main-title {
  margin-top: 10px;
}
.main-timeline2 .bd_n:before {
  display: none;
}
.item_title .main-title {
  margin-top: 0;
}
</style>
